body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  transition: 300ms ease-in-out;
}

::-webkit-scrollbar {
  width: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link-hover:hover {
  background-color: #34406b;
  cursor: pointer;
}

.modal-90 {
  width: 90%;
}

.form-control-editor {
  /* height: 570px; */
  /* width: 100%; */
  background-color: #ffffff;
}

td {
  /* overflow: hidden; */
  max-width: 400px;
  word-wrap: break-word;
}

.internet-error {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 4vh;
  color: #ffffff;
  /* width: 100vw; */
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: red;
  align-items: center;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invert-stepper {
  display: flex;
  flex-direction: column-reverse;
}

.spinner {
  /* margin: 100px auto; */
  width: 50px;
  /* height: 40px; */
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #ffffff;
  height: 10px;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.disablediv {
  pointer-events: none;
  opacity: 0.4;
}

.visal-drawer {
  z-index: 1003;
}

.reference {
  display: flex;
  line-height: 10px;
}

.reference-header {
  margin-right: 5px;
  width: 70px;
}

.debit-center {
  padding-left: 100px;
}

.reference-value {
}

.t_t_h {
  color: #000;
  font-weight: 600;
}

.t_t_h_H {
  color: #000000;
  font-weight: 900;
  font-variant: small-caps;
}

.t_t_v {
  color: #000;
}

.row-bottom {
  margin: 0px !important;
}

.insurer-debit-note-anim {
  transition: width 2s;
}
