.drawer {
  transition: all ease-in;
}

.close_button {
  height: 100%;
  width: 50px;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f14646;
  font-size: larger;
  font-weight: bolder;
}

.drawer_container {
  height: 100vh;
  /* padding: 10px; */
  z-index: 1040;
}

.overlay {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1002;
  /* // Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
