
//
// Select 2
//

$input-bg: #2E3548;
$input-border-color: #f0f0f0;
$input-color: #ffffff;
$gray-500: #a0aec0;
$gray-600:#718096;
$gray-200:#edf2f7;
$text-muted: #b4a19d;
$dropdown-bg: #2E3548;
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #556ee6;
$indigo:  #564ab1;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #f46a6a;
$orange:  #f1734f;
$yellow:  #f1b44c;
$green:   #34c38f;
$teal:    #050505;
$cyan:    #50a5f1;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-800;
$box-shadow:               0 .125rem .25rem rgba($black, .075);

$dropdown-link-active-bg:           $gray-100;
$dropdown-link-active-color:        darken($gray-900, 5%);
$dropdown-border-color:             rgba($black, .15);




.select2-container {
    .select2-selection__control {
      background-color: $input-bg !important;
      border: 1px solid $input-border-color !important;
      height: 38px;
      &:focus{
        outline: none;
      }
      &.select2-selection__control--is-focused {
        box-shadow: none !important;
      }
      .select2-selection__rendered {
        line-height: 36px;
        padding-left: 12px;
        color: $input-color;
      }
      .select2-selection__arrow {
        height: 34px;
        width: 34px;
        right: 3px;
  
        b{
          border-color: $gray-500 transparent transparent transparent;
          border-width: 6px 6px 0 6px;
        }
      }
    }
  }
  .select2-selection__placeholder, .select2-selection__single-value {
    color: $text-muted !important;
  }
  .select2-selection__indicator-separator {
    display: none;
  }
  .select2-container--open {
    .select2-selection {
  
      .select2-selection__arrow {
  
        b{
          border-color: transparent transparent $gray-500 transparent !important;
          border-width: 0 6px 6px 6px !important;
        }
      }
    }
  }
  .select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: $dropdown-bg;
        .select2-search__field {
            border: 1px solid  $input-border-color;
            background-color: $input-bg;
            color: $gray-600;
            outline: none;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }
    .select2-results__option[aria-selected=true] {
        background-color: $dropdown-link-active-bg;
        color: $dropdown-link-active-color;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
  }
  .select2-results__option {
    padding: 6px 12px;
  }
  .select2-selection__menu {
    border: 1px solid $dropdown-border-color !important;
    background-color: $dropdown-bg !important;
    box-shadow: $box-shadow !important;
    z-index: 999 !important;
    .select2-selection__option {
      &.select2-selection__option--is-focused {
        background-color: $primary !important;
      }
    }
  }
  .select2-search {
    input{
      border: 1px solid $gray-300;
    }
  }
  .select2-container {
    .select2-selection--multiple {
      min-height: 38px;
      background-color: $input-bg;
      border: 1px solid $input-border-color !important;
    
      .select2-selection__rendered {
        padding: 2px 10px;
      }
      .select2-search__field {
        border: 0;
        color: $input-color;
        &::placeholder{
            color: $input-color;
        }
    }
      .select2-selection__choice {
        background-color: $gray-200;
        border: 1px solid $gray-300;
        border-radius: 1px;
        padding: 0 7px;
      }
    }
  }
  .select2-container--default{
    &.select2-container--focus {
      .select2-selection--multiple{
        border-color: $gray-400;
      }
    }
  }
  